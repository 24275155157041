<template>
  <div class="cont">
    <!-- <headers></headers> -->
    <div class="head-box">
      <div class="title-box">
        <div class="title">课节详情</div>
      </div>
      <div class="top-box">
        <div class="spacing">课程名称：{{ courseName }}</div>
        <div class="spacing">上课时间：{{ time }}</div>
        <div>时长：{{ duration }}分</div>
      </div>
    </div>
    <div class="bot-box">
      <div class="title-box">
        <div class="title">观看数据</div>
      </div>
      <div class="bot-block">
        <div class="box-top">
          <el-input
            placeholder="请输入发布人姓名/关键字"
            size="small"
            style="margin-left:23px;width:237px"
            suffix-icon="el-icon-search"
            v-model="search"
          ></el-input>
        </div>
        <div class="box-bot">
          <img
            style="vertical-align: middle;"
            src="@/assets/images/course/icon-rect.png"
            alt
          />
          <span style="margin-left:12px;margin-right:33px;">下载表格</span>
        </div>
      </div>
      <discarea :isshowdel="true"></discarea>
      <discarea :isshowdel="true"></discarea>
      <el-pagination
        style="text-align:right;margin-top:49px"
        :page-sizes="[20, 10, 5]"
        :page-size="100"
        background
        layout="prev, pager, next,sizes"
        :total="1000"
      ></el-pagination>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      courseName: "普通话培训",
      time: "2020.06.13 8:30-10:30",
      duration: "120",
      search: "",
      activeName: "first",
      tableData: [
        {
          id: "1",
          name: "李小小",
          no: "11616",
          rolename: "参训学生",
          phone: "135****5858",
          area: "宁夏省银川市",
          school: "金凤区第三小区",
          entertime: "2020.08.23 12:00",
          watchtime: "20分钟",
          watchterminal: "PC端",
          IP: "192.122.21"
        },
        {
          id: "1",
          name: "李小小",
          no: "11616",
          rolename: "参训学生",
          phone: "135****5858",
          area: "宁夏省银川市",
          school: "金凤区第三小区",
          entertime: "2020.08.23 12:00",
          watchtime: "20分钟",
          watchterminal: "PC端",
          IP: "192.122.21"
        }
      ]
    };
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
    }
  },
  mounted() {
    // console.log(new Date());
  }
};
</script>

<style lang="less" scoped>
// @import "./less/table.less";

/*媒体查询*/
/*当页面大于1200px 时，大屏幕，主要是PC 端*/
@media (min-width: 1200px) {
  .head-box {
    box-shadow: 0px 5px 29px 0px rgba(22, 115, 255, 0.16);
    border-radius: 10px;
    width: 1200px;
    margin: 98px auto 37px;
    background: #ffffff;
    padding-bottom: 24px;
  }
  .bot-box {
    box-shadow: 0px 5px 29px 0px rgba(22, 115, 255, 0.16);
    border-radius: 10px;
    width: 1200px;
    margin: 0 auto 37px;
    background: #ffffff;
    padding-bottom: 24px;
  }
}

/*在992 和1199 像素之间的屏幕里，中等屏幕，分辨率低的PC*/
@media (min-width: 992px) and (max-width: 1199px) {
  .head-box {
    box-shadow: 0px 5px 29px 0px rgba(22, 115, 255, 0.16);
    border-radius: 10px;
    width: 950px;
    margin: 98px auto 37px;
    background: #ffffff;
    padding-bottom: 24px;
  }
  .bot-box {
    box-shadow: 0px 5px 29px 0px rgba(22, 115, 255, 0.16);
    border-radius: 10px;
    width: 950px;
    margin: 0 auto 37px;
    background: #ffffff;
    padding-bottom: 24px;
  }
}

.el-tabs__header {
  line-height: 77px;
  .el-tabs__item {
    padding: 0 25px !important;
    font-size: 20px;
    font-weight: bold;
    color: #9c9b9b;
  }
  .el-tabs__item:hover {
    color: #2373ec;
  }
  .el-tabs__item.is-active {
    color: #2373ec;
  }
}

.el-tabs__active-bar {
  height: 5px;
  border-radius: 3px;
  background-color: #2373ec;
}
.title-box {
  height: 77px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f2f2f2;
  .title {
    font-size: 22px;
    color: #2373ec;
    padding-top: 30px;
    padding-bottom: 10px;
    margin-left: 47px;
    width: 153px;
    border-bottom: 5px solid #2373ec;
    text-align: center;
  }
}
.top-box {
  display: flex;
  margin: 47px 51px 47px 39px;
  .spacing {
    margin-right: 50px;
  }
  .btn {
    width: 165px;
    height: 43px;
    background: linear-gradient(90deg, #2272ec, #3a7bf6);
    box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
    border-radius: 5px;
    font-size: 19px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 38px;
  }
}
.bot-block {
  .select {
    width: 97px;
    height: 30px;
    margin-right: 50px;
    text-align: center;
    text-align-last: center;
  }
  .box-bot {
    margin-top: 24px;
    text-align: right;
  }
}
.mid-bot {
  margin: 31px 13px 49px;
}
</style>
